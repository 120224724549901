.root {
  display: flex;
  flex-direction: column;
  width: 100%;
  background: var(--token-AB3mswlcs);
  position: relative;
  min-width: 0;
  padding: 64px 40px 10px;
}
.root > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  min-width: 0;
  margin-top: calc(0px - 20px);
  height: calc(100% + 20px);
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-top: 20px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .root {
    display: flex;
    flex-direction: column;
    width: 100%;
    background: #090602;
    min-width: 0;
    padding: 64px 40px 10px;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .root > :global(.__wab_flex-container) {
    flex-direction: column;
    align-items: center;
    min-width: 0;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 20px);
    height: calc(100% + 20px);
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .root > :global(.__wab_flex-container) > *,
  .root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .root > :global(.__wab_flex-container) > picture > img,
  .root
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 20px;
  }
}
.freeBox__owV8Y {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  max-width: 1200px;
  padding-right: 40px;
  padding-left: 40px;
  margin-bottom: 29px;
  padding-bottom: 11px;
  min-width: 0;
}
.freeBox__owV8Y > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-width: 0;
  margin-left: calc(0px - 16px);
  width: calc(100% + 16px);
}
.freeBox__owV8Y > :global(.__wab_flex-container) > *,
.freeBox__owV8Y > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__owV8Y > :global(.__wab_flex-container) > picture > img,
.freeBox__owV8Y
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 16px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__owV8Y {
    display: flex;
    flex-direction: column;
    padding-left: 7px;
    padding-right: 0px;
    position: relative;
    width: 100%;
    max-width: 1200px;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__owV8Y > :global(.__wab_flex-container) {
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    min-width: 0;
    margin-left: calc(0px - 16px);
    width: calc(100% + 16px);
    margin-top: calc(0px - 16px);
    height: calc(100% + 16px);
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__owV8Y > :global(.__wab_flex-container) > *,
  .freeBox__owV8Y > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__owV8Y > :global(.__wab_flex-container) > picture > img,
  .freeBox__owV8Y
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 16px;
    margin-top: 16px;
  }
}
.freeBox__s5NTp {
  display: flex;
  position: relative;
  flex-direction: column;
  top: auto;
  left: auto;
  width: 280px;
  flex-shrink: 0;
}
.freeBox__s5NTp > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: calc(0px - 16px);
  height: calc(100% + 16px);
}
.freeBox__s5NTp > :global(.__wab_flex-container) > *,
.freeBox__s5NTp > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__s5NTp > :global(.__wab_flex-container) > picture > img,
.freeBox__s5NTp
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 16px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__s5NTp {
    align-self: flex-start;
    display: flex;
    position: relative;
    flex-direction: column;
    top: auto;
    left: auto;
    width: 280px;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__s5NTp > :global(.__wab_flex-container) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 16px);
    height: calc(100% + 16px);
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__s5NTp > :global(.__wab_flex-container) > *,
  .freeBox__s5NTp > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__s5NTp > :global(.__wab_flex-container) > picture > img,
  .freeBox__s5NTp
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 16px;
  }
}
.freeBox__lqck9 {
  display: flex;
  position: relative;
  flex-direction: column;
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__lqck9 {
    align-self: flex-start;
    display: flex;
    position: relative;
    flex-direction: column;
  }
}
.text__roOjv {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  font-size: 25px;
  font-family: "Lora", serif;
  min-width: 0;
}
.link__mYzr6 {
  position: relative;
  left: auto;
  top: auto;
  color: var(--token-YsAcaIub0);
  font-weight: 600;
  text-transform: capitalize;
  text-decoration-line: none;
  border-bottom: 1px none var(--token-RQb5Y4IJtbc);
}
.root .link__mYzr6:hover {
  font-weight: 600;
  text-transform: capitalize;
  text-decoration-line: none;
}
.link__weTun {
  position: relative;
  left: auto;
  top: auto;
  color: var(--token-YsAcaIub0);
  font-weight: 600;
  text-transform: capitalize;
  text-decoration-line: none;
  border-bottom: 1px none var(--token-RQb5Y4IJtbc);
}
.root .link__weTun:hover {
  font-weight: 600;
  text-transform: capitalize;
  text-decoration-line: none;
  left: auto;
  top: auto;
}
.freeBox__i6O {
  display: flex;
  position: relative;
  flex-direction: row;
}
.freeBox__i6O > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.freeBox__i6O > :global(.__wab_flex-container) > *,
.freeBox__i6O > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__i6O > :global(.__wab_flex-container) > picture > img,
.freeBox__i6O
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__i6O {
    position: relative;
    flex-direction: row;
    display: none;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__i6O > :global(.__wab_flex-container) {
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    margin-left: calc(0px - 8px);
    width: calc(100% + 8px);
    margin-top: calc(0px - 8px);
    height: calc(100% + 8px);
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__i6O > :global(.__wab_flex-container) > *,
  .freeBox__i6O > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__i6O > :global(.__wab_flex-container) > picture > img,
  .freeBox__i6O
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 8px;
    margin-top: 8px;
  }
}
.link___1NdMx {
  position: relative;
  transition-property: all;
  transition-duration: 0.5s;
  transition-timing-function: ease-out;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  -webkit-transition-property: all;
  -webkit-transition-timing-function: ease-out;
  -webkit-transition-duration: 0.5s;
  border-radius: 6px;
  padding: 4px 0px;
  border: 1px solid transparent;
}
.link___1NdMx > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.link___1NdMx > :global(.__wab_flex-container) > *,
.link___1NdMx > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.link___1NdMx > :global(.__wab_flex-container) > picture > img,
.link___1NdMx
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.svg__pOkve {
  width: 24px;
  height: 24px;
  position: relative;
  object-fit: cover;
  color: #05050599;
  flex-shrink: 0;
}
.svg__aSkbQ {
  width: 24px;
  height: 24px;
  position: relative;
  object-fit: cover;
  color: #05050599;
  flex-shrink: 0;
}
.freeBox__whmJ5 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
}
.button {
  font-size: 18px;
  line-height: 28px;
  color: #ffffff;
  font-weight: 500;
  background: #000000d9;
  border-radius: 6px;
  border-width: 0px;
}
.link__rnwLr {
  position: relative;
  transition-property: all;
  transition-duration: 0.5s;
  transition-timing-function: ease-out;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  -webkit-transition-property: all;
  -webkit-transition-timing-function: ease-out;
  -webkit-transition-duration: 0.5s;
  border-radius: 6px;
  padding: 10px 16px;
  border: 1px solid transparent;
}
.link__rnwLr > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.link__rnwLr > :global(.__wab_flex-container) > *,
.link__rnwLr > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.link__rnwLr > :global(.__wab_flex-container) > picture > img,
.link__rnwLr
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.svg___6FbUq {
  width: 24px;
  height: 24px;
  position: relative;
  object-fit: cover;
  color: #05050599;
  flex-shrink: 0;
}
.text___7HDlF {
  font-weight: 400;
  color: #05050599;
  font-size: 18px;
  line-height: 26px;
}
.svg__l8N0J {
  width: 24px;
  height: 24px;
  position: relative;
  object-fit: cover;
  color: #05050599;
  flex-shrink: 0;
}
.freeBox__qlqDl {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 1200px;
  border-top-style: solid;
  border-top-width: 1px;
  min-width: 0;
  padding: 8px 8px 0px 30px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__qlqDl {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: auto;
    max-width: 1200px;
    border-top-style: solid;
    border-top-width: 1px;
    min-width: 0;
    padding: 8px 0px 0px;
  }
}
