.root {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: var(--token-AB3mswlcs);
  position: relative;
  min-width: 0;
  padding: 0px;
}
.navigationBar:global(.__wab_instance) {
  width: 102.92%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  max-width: 100%;
  object-fit: cover;
  background: var(--token-AB3mswlcs);
  font-weight: 400;
  padding: 16px;
}
.h1 {
  font-size: 40px;
  text-align: left;
  max-width: 480px;
  width: 100%;
  color: var(--token-YsAcaIub0);
  padding-right: 0px;
  margin-right: 0px;
  background: var(--token-AB3mswlcs);
  margin-left: 0px;
  padding-left: 31px;
  padding-top: 0px;
  font-family: "Lora", serif;
  font-weight: 500;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .h1 {
    font-size: 25px;
  }
}
.img__vtY1W {
  object-fit: cover;
  max-width: 22px;
  width: 100%;
  min-width: 0;
}
.img__vtY1W > picture > img {
  object-fit: cover;
}
.img__vtY1W > :global(.__wab_img-spacer) > img {
  object-fit: cover;
}
.img__g1Gt3 {
  object-fit: cover;
}
.img__g1Gt3 > picture > img {
  object-fit: cover;
}
.img__g1Gt3 > :global(.__wab_img-spacer) > img {
  object-fit: cover;
}
.link__m24Pa {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 80px;
  color: var(--token-YsAcaIub0);
  padding-right: 0px;
  font-family: "Lora", serif;
  font-weight: 400;
  margin-right: 7px;
  min-width: 0;
}
.link__gWeqQ {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  color: var(--token-YsAcaIub0);
  padding-right: 33px;
  font-family: "Lora", serif;
  font-weight: 400;
}
.link__ib00 {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  color: var(--token-YsAcaIub0);
  padding-right: 37px;
  font-family: "Lora", serif;
  font-weight: 400;
}
.link___1VFv4 {
  position: relative;
  color: var(--token-YsAcaIub0);
  align-self: center;
  margin-right: 42px;
  font-weight: 400;
  font-family: "Lora", serif;
}
