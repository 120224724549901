.root {
  color: var(--token-YsAcaIub0);
  font-size: 16px;
  font-weight: 500;
  width: 202px;
  background: linear-gradient(var(--token-AB3mswlcs), var(--token-AB3mswlcs)),
    var(--token-FfftQnWVlqF);
  position: relative;
  font-family: "Lora", serif;
  text-align: center;
  height: 60px;
  padding-top: 17px;
  padding-bottom: 20px;
  margin-right: 2px;
  border-radius: 5px;
  border: 1.5px solid var(--token-YsAcaIub0);
}
