.root {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-width: 0;
  min-height: 0;
  padding: 0px;
}
.root > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  min-height: 0;
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-top: 0px;
}
.homepageNavbar:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.freeBox__teGBb {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__teGBb {
    padding-top: 0px;
  }
}
.freeBox__eqKif {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: none;
  background: var(--token-AB3mswlcs);
  min-width: 0;
  padding: 0px;
}
.text__fFSwv {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 833px;
  margin-top: 70px;
  font-size: 5em;
  margin-bottom: 70px;
  font-family: "Lora", serif;
  text-align: center;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .text__fFSwv {
    text-align: center;
    font-size: 2.5em;
    padding-right: 33px;
    padding-left: 34px;
  }
}
.img__b2QqL {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 392px;
  width: 514px;
  flex-shrink: 0;
  display: none !important;
}
.img__b2QqL > picture > img {
  object-fit: cover;
}
.img__b2QqL > :global(.__wab_img-spacer) > img {
  object-fit: cover;
}
@media (min-width: 0px) and (max-width: 768px) {
  .img__b2QqL {
    display: inline-block !important;
  }
}
.text__cy9Sn {
  position: relative;
  max-width: 529px;
  width: 100%;
  background: #ffffff;
  align-self: flex-end;
  left: auto;
  top: auto;
  margin-right: 0px;
  min-width: 0;
  padding: 48px 19px 55px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .text__cy9Sn {
    left: auto;
    top: auto;
    display: block;
  }
}
.columns__jaCbY {
  --plsmc-rc-col-gap: 0px;
}
.columns__jaCbY {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  align-items: stretch;
  flex-shrink: 1;
  flex-direction: row;
  flex-wrap: nowrap;
  min-width: 0;
  padding: 0px;
}
.columns__jaCbY > :nth-child(1n + 1) {
  width: calc((100% - 1 * var(--plsmc-rc-col-gap, 0px)) * 12 / 12);
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__jaCbY {
    width: 100%;
    height: auto;
    flex-direction: row;
    flex-wrap: wrap;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__jaCbY > :nth-child(1n + 1) {
    width: calc((100% - 1 * var(--plsmc-rc-col-gap, 0px)) * 12 / 12);
  }
}
.column__ajQv {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
  padding: 2px 8px 0px 0px;
}
.columns__soO0 {
  --plsmc-rc-col-gap: 0px;
}
.columns__soO0 {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  align-items: stretch;
  flex-shrink: 1;
  flex-direction: row;
  flex-wrap: nowrap;
  min-width: 0;
  padding: 8px;
}
.columns__soO0 > :nth-child(2n + 1) {
  width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 6 / 12);
}
.columns__soO0 > :nth-child(2n + 2) {
  width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 6 / 12);
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__soO0 {
    width: 100%;
    height: auto;
    flex-direction: row;
    flex-wrap: wrap;
    position: absolute;
    left: 0px;
    top: -6px;
    padding-right: 0px;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__soO0 > :nth-child(1n + 1) {
    width: calc((100% - 1 * var(--plsmc-rc-col-gap, 0px)) * 12 / 12);
  }
}
.column___2Usu5 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  background: url("./images/hrsPng.jpg") top 50% left 50% / cover repeat;
  transform: translateX(140px) translateY(0px) translateZ(0px);
  align-self: flex-start;
  min-width: 0;
  padding: 8px 0px 445px 8px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .column___2Usu5 {
    justify-content: flex-start;
    align-items: flex-start;
    padding-right: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
    transform: translateX(0px) translateY(0px) translateZ(0px);
    overflow: auto;
    align-self: flex-start;
  }
}
.whiteContainer {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  background: var(--token-AB3mswlcs);
  overflow: visible;
  transform: translateX(80px) translateY(0px) translateZ(0px);
  min-width: 0;
  padding: 0px 8px 8px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .whiteContainer {
    padding-right: 48px;
    display: none;
  }
}
.text__u4Gfl {
  position: absolute;
  max-width: 527px;
  width: 100%;
  align-self: flex-start;
  margin-top: 0px;
  font-size: 20px;
  margin-right: 0px;
  margin-left: 0px;
  background: #ffffff;
  left: -97px;
  top: 114px;
  z-index: 1;
  transition-property: all;
  transition-duration: 1s;
  min-width: 0;
  display: block;
  -webkit-transition-property: all;
  -webkit-transition-duration: 1s;
  padding: 50px 55px 50px 50px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .text__u4Gfl {
    padding-right: 0px;
    margin-right: 0px;
    max-width: 250px;
    align-self: auto;
    position: absolute;
    left: 160px;
    top: -160px;
    padding-top: 0px;
    padding-left: 0px;
    transform: translateX(0px) translateY(0px) translateZ(0px);
  }
}
.text__tCkF4 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
  display: none;
}
@media (min-width: 0px) and (max-width: 768px) {
  .text__tCkF4 {
    display: none;
  }
}
.freeBox___8IVWe {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px;
}
.freeBox__fgdM {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: none;
  min-width: 0;
  padding: 0px;
}
.columns__hHvRj {
  --plsmc-rc-col-gap: 0px;
}
.columns__hHvRj {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  align-items: stretch;
  flex-shrink: 1;
  flex-direction: row;
  flex-wrap: nowrap;
  min-width: 0;
  padding: 0px;
}
.columns__hHvRj > :nth-child(1n + 1) {
  width: calc((100% - 1 * var(--plsmc-rc-col-gap, 0px)) * 12 / 12);
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__hHvRj {
    width: 100%;
    height: auto;
    flex-direction: row;
    flex-wrap: wrap;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__hHvRj > :nth-child(1n + 1) {
    width: calc((100% - 1 * var(--plsmc-rc-col-gap, 0px)) * 12 / 12);
  }
}
.column__k2VTd {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  background: var(--token-AB3mswlcs);
  min-width: 0;
  padding: 0px;
  border-width: 0px;
  border-style: none;
}
.columns__qSbPt {
  --plsmc-rc-col-gap: 0px;
}
.columns__qSbPt {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  align-items: stretch;
  flex-shrink: 1;
  flex-direction: row;
  flex-wrap: nowrap;
  min-width: 0;
  padding: 69px 8px 8px;
}
.columns__qSbPt > :nth-child(2n + 1) {
  width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 6 / 12);
}
.columns__qSbPt > :nth-child(2n + 2) {
  width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 6 / 12);
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__qSbPt {
    width: 100%;
    height: auto;
    flex-direction: row;
    flex-wrap: wrap;
    position: relative;
    left: auto;
    top: auto;
    z-index: 1;
    opacity: 1;
    padding-top: 45px;
    padding-left: 26px;
    padding-right: 26px;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__qSbPt > :nth-child(1n + 1) {
    width: calc((100% - 1 * var(--plsmc-rc-col-gap, 0px)) * 12 / 12);
  }
}
.column___2VI {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .column___2VI {
    padding-top: 0px;
    padding-bottom: 0px;
  }
}
.text__hJlKd {
  position: relative;
  max-width: 588px;
  width: 100%;
  color: var(--token-0g_OA7ttS-E);
  text-align: left;
  align-self: center;
  font-size: 24px;
  margin-top: 4px;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .text__hJlKd {
    margin-top: 0px;
    font-size: 19px;
    margin-bottom: 38px;
  }
}
.column__p7ScL {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px 8px 73px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .column__p7ScL {
    padding-bottom: 0px;
  }
}
.text__o3Qoi {
  position: relative;
  max-width: 659px;
  width: 100%;
  color: var(--token-0g_OA7ttS-E);
  font-size: 24px;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .text__o3Qoi {
    font-size: 19px;
    padding-bottom: 61px;
    display: block;
  }
}
.freeBox__beD7K {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__beD7K {
    padding-bottom: 0px;
    padding-top: 0px;
  }
}
.img__bnKNs {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 1452px;
  height: 686px;
  flex-shrink: 0;
}
.img__bnKNs > picture > img {
  object-fit: cover;
}
.img__bnKNs > :global(.__wab_img-spacer) > img {
  object-fit: cover;
}
@media (min-width: 0px) and (max-width: 768px) {
  .img__bnKNs {
    height: 485px;
    width: 567px;
    flex-shrink: 0;
    display: none !important;
  }
}
.freeBox__ojRq {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  background: var(--token-FfftQnWVlqF);
  min-width: 0;
  padding: 4rem 0px;
}
.freeBox__ojRq > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 0;
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.freeBox__ojRq > :global(.__wab_flex-container) > *,
.freeBox__ojRq > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__ojRq > :global(.__wab_flex-container) > picture > img,
.freeBox__ojRq
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 0px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__ojRq {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    background: var(--token-FfftQnWVlqF);
    min-width: 0;
    padding: 50px 32px;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__ojRq > :global(.__wab_flex-container) {
    flex-direction: column;
    align-items: center;
    min-width: 0;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__ojRq > :global(.__wab_flex-container) > *,
  .freeBox__ojRq > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__ojRq > :global(.__wab_flex-container) > picture > img,
  .freeBox__ojRq
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 0px;
  }
}
.freeBox__rssZf {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 0;
  padding: 0px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__rssZf {
    display: flex;
  }
}
.text__p242 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  margin-bottom: 28px;
  font-size: 5em;
  font-family: "Lora", serif;
  font-weight: 600;
  left: auto;
  top: auto;
  text-align: center;
  min-width: 0;
}
.img__rDPiU {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 100%;
  left: auto;
  top: auto;
  min-width: 0;
}
.img__rDPiU > picture > img {
  object-fit: cover;
}
.img__rDPiU > :global(.__wab_img-spacer) > img {
  object-fit: cover;
}
.freeBox__e9D9R {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: calc(31px + 0px) !important;
  min-width: 0;
  padding: 0px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__e9D9R {
    margin-top: calc(31px + 0px) !important;
    display: flex;
  }
}
.text__e34YY {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  margin-bottom: 28px;
  font-size: 5em;
  font-family: "Lora", serif;
  font-weight: 600;
  left: auto;
  top: auto;
  text-align: center;
  min-width: 0;
}
.img__wNkwi {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 100%;
  left: auto;
  top: auto;
  min-width: 0;
}
.img__wNkwi > picture > img {
  object-fit: cover;
}
.img__wNkwi > :global(.__wab_img-spacer) > img {
  object-fit: cover;
}
.freeBox__hu5Wh {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: calc(34px + 0px) !important;
  min-width: 0;
  padding: 0px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__hu5Wh {
    margin-top: calc(34px + 0px) !important;
    display: flex;
  }
}
.text___4PEyX {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  margin-bottom: 28px;
  font-size: 5em;
  font-family: "Lora", serif;
  font-weight: 600;
  left: auto;
  top: auto;
  text-align: center;
  min-width: 0;
}
.img__varoS {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 100%;
  left: auto;
  top: auto;
  min-width: 0;
}
.img__varoS > picture > img {
  object-fit: cover;
}
.img__varoS > :global(.__wab_img-spacer) > img {
  object-fit: cover;
}
.homepageFooter:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
