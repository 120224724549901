@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro%3Aital%2Cwght%400%2C400%3B0%2C600%3B0%2C700%3B0%2C900&family=Inter%3Aital%2Cwght%400%2C400%3B0%2C500%3B0%2C600%3B0%2C700%3B0%2C900&family=Inconsolata%3Aital%2Cwght%400%2C400%3B0%2C500%3B0%2C600%3B0%2C700%3B0%2C900&family=Lora%3Aital%2Cwght%400%2C400%3B0%2C500%3B0%2C600%3B0%2C700&display=swap");

.plasmic_tokens {
  --token-aOibEfNiciT: var(--token-52DISS5KQhy);
  --plasmic-token-unnamed-style-token: var(--token-aOibEfNiciT);
  --token-0g_OA7ttS-E: #ffffff;
  --plasmic-token-white: var(--token-0g_OA7ttS-E);
  --token-RQb5Y4IJtbc: #1c1a17;
  --plasmic-token-black: var(--token-RQb5Y4IJtbc);
  --token-52DISS5KQhy: #898484;
  --plasmic-token-test-color: var(--token-52DISS5KQhy);
  --token-FfftQnWVlqF: #f2efeb;
  --plasmic-token-bg: var(--token-FfftQnWVlqF);
  --token-Wa9fy1coHC_: 146px;
  --plasmic-token-box: var(--token-Wa9fy1coHC_);
  --token-5u94y4Qz9FE: #a36005;
  --plasmic-token-brown: var(--token-5u94y4Qz9FE);
  --token-eDb__ztCv75: #faf8f5;
  --plasmic-token-bg-2: var(--token-eDb__ztCv75);
  --token-ftHwoaT5jkL: #e63360;
  --plasmic-token-star: var(--token-ftHwoaT5jkL);
  --token-YsAcaIub0: #c2a191;
  --plasmic-token-ylatna: var(--token-YsAcaIub0);
  --token-AB3mswlcs: #0e1629;
  --plasmic-token-plava-royal-blue: var(--token-AB3mswlcs);
}

.plasmic_tokens {
  --plsmc-standard-width: 800px;
  --plsmc-wide-width: 1280px;
  --plsmc-viewport-gap: 16px;
  --plsmc-wide-chunk: calc(
    ((var(--plsmc-wide-width) - var(--plsmc-standard-width)) / 2) -
      var(--plsmc-viewport-gap)
  );
}

.plasmic_default_styles {
  --mixin-AblFl5qF5ihA_font-family: "Source Sans Pro", sans-serif;
  --mixin-AblFl5qF5ihA_font-size: 14px;
  --mixin-AblFl5qF5ihA_font-weight: 500;
  --mixin-AblFl5qF5ihA_font-style: normal;
  --mixin-AblFl5qF5ihA_color: var(--token-YsAcaIub0);
  --mixin-AblFl5qF5ihA_text-align: left;
  --mixin-AblFl5qF5ihA_text-transform: none;
  --mixin-AblFl5qF5ihA_line-height: 1.5;
  --mixin-AblFl5qF5ihA_letter-spacing: normal;
  --mixin-AblFl5qF5ihA_white-space: pre-wrap;
  --mixin-AblFl5qF5ihA_user-select: text;
  --mixin-AblFl5qF5ihA_text-decoration-line: none;
  --mixin-AblFl5qF5ihA_text-overflow: clip;
  --mixin-V7HNa5m19ZAQ_color: var(--token-0g_OA7ttS-E);
  --mixin-V7HNa5m19ZAQ_font-weight: 700;
  --mixin-V7HNa5m19ZAQ_font-size: 45px;
  --mixin-V7HNa5m19ZAQ_line-height: 1;
  --mixin-V7HNa5m19ZAQ_letter-spacing: 0px;
  --mixin-V7HNa5m19ZAQ_font-family: "Source Sans Pro", sans-serif;
  --mixin-V7HNa5m19ZAQ_text-align: center;
  --mixin-V7HNa5m19ZAQ_white-space: pre-wrap;
  --mixin-T3OoqWGpfU_h_color: var(--token-RQb5Y4IJtbc);
  --mixin-T3OoqWGpfU_h_font-weight: 900;
  --mixin-T3OoqWGpfU_h_font-size: 30px;
  --mixin-T3OoqWGpfU_h_line-height: 1.1;
  --mixin-T3OoqWGpfU_h_letter-spacing: 0px;
  --mixin-T3OoqWGpfU_h_font-family: "Source Sans Pro", sans-serif;
  --mixin-T3OoqWGpfU_h_white-space: pre-wrap;
  --mixin-yr6e-AnHZQPH_color: var(--token-RQb5Y4IJtbc);
  --mixin-yr6e-AnHZQPH_font-size: 16px;
  --mixin-yr6e-AnHZQPH_text-transform: none;
  --mixin-yr6e-AnHZQPH_font-family: "Source Sans Pro", sans-serif;
  --mixin-yr6e-AnHZQPH_font-weight: 700;
  --mixin-yr6e-AnHZQPH_text-decoration-line: none;
  --mixin-yr6e-AnHZQPH_white-space: pre-wrap;
  --mixin-Xef1mibqwsOk_color: var(--token-RQb5Y4IJtbc);
  --mixin-Xef1mibqwsOk_font-weight: 900;
  --mixin-Xef1mibqwsOk_font-size: 16px;
  --mixin-Xef1mibqwsOk_line-height: 1.2;
  --mixin-Xef1mibqwsOk_letter-spacing: 0px;
  --mixin-Xef1mibqwsOk_font-family: "Source Sans Pro", sans-serif;
  --mixin-Xef1mibqwsOk_white-space: pre-wrap;
  --mixin-I_gcNeGjfyB1_font-family: "Inter", sans-serif;
  --mixin-I_gcNeGjfyB1_color: #000000;
  --mixin-I_gcNeGjfyB1_font-size: 24px;
  --mixin-I_gcNeGjfyB1_font-weight: 600;
  --mixin-I_gcNeGjfyB1_letter-spacing: -0.5px;
  --mixin-I_gcNeGjfyB1_line-height: 1.3;
  --mixin-I_gcNeGjfyB1_white-space: pre-wrap;
  --mixin-o3Ksqk6jDPX3_background: linear-gradient(#f8f8f8, #f8f8f8);
  --mixin-o3Ksqk6jDPX3_border-bottom-color: #dddddd;
  --mixin-o3Ksqk6jDPX3_border-bottom-style: solid;
  --mixin-o3Ksqk6jDPX3_border-bottom-width: 1px;
  --mixin-o3Ksqk6jDPX3_border-left-color: #dddddd;
  --mixin-o3Ksqk6jDPX3_border-left-style: solid;
  --mixin-o3Ksqk6jDPX3_border-left-width: 1px;
  --mixin-o3Ksqk6jDPX3_border-right-color: #dddddd;
  --mixin-o3Ksqk6jDPX3_border-right-style: solid;
  --mixin-o3Ksqk6jDPX3_border-right-width: 1px;
  --mixin-o3Ksqk6jDPX3_border-top-color: #dddddd;
  --mixin-o3Ksqk6jDPX3_border-top-style: solid;
  --mixin-o3Ksqk6jDPX3_border-top-width: 1px;
  --mixin-o3Ksqk6jDPX3_border-bottom-left-radius: 3px;
  --mixin-o3Ksqk6jDPX3_border-bottom-right-radius: 3px;
  --mixin-o3Ksqk6jDPX3_border-top-left-radius: 3px;
  --mixin-o3Ksqk6jDPX3_border-top-right-radius: 3px;
  --mixin-o3Ksqk6jDPX3_font-family: "Inconsolata";
  --mixin-o3Ksqk6jDPX3_padding-bottom: 1px;
  --mixin-o3Ksqk6jDPX3_padding-left: 4px;
  --mixin-o3Ksqk6jDPX3_padding-right: 4px;
  --mixin-o3Ksqk6jDPX3_padding-top: 1px;
  --mixin-o3Ksqk6jDPX3_white-space: pre-wrap;
  --mixin-r4DaWbUlDAKT_border-left-color: #dddddd;
  --mixin-r4DaWbUlDAKT_border-left-style: solid;
  --mixin-r4DaWbUlDAKT_border-left-width: 3px;
  --mixin-r4DaWbUlDAKT_color: #888888;
  --mixin-r4DaWbUlDAKT_padding-left: 10px;
  --mixin-r4DaWbUlDAKT_white-space: pre-wrap;
  --mixin-1r97PxVTpGz__background: linear-gradient(#f8f8f8, #f8f8f8);
  --mixin-1r97PxVTpGz__border-bottom-color: #dddddd;
  --mixin-1r97PxVTpGz__border-bottom-style: solid;
  --mixin-1r97PxVTpGz__border-bottom-width: 1px;
  --mixin-1r97PxVTpGz__border-left-color: #dddddd;
  --mixin-1r97PxVTpGz__border-left-style: solid;
  --mixin-1r97PxVTpGz__border-left-width: 1px;
  --mixin-1r97PxVTpGz__border-right-color: #dddddd;
  --mixin-1r97PxVTpGz__border-right-style: solid;
  --mixin-1r97PxVTpGz__border-right-width: 1px;
  --mixin-1r97PxVTpGz__border-top-color: #dddddd;
  --mixin-1r97PxVTpGz__border-top-style: solid;
  --mixin-1r97PxVTpGz__border-top-width: 1px;
  --mixin-1r97PxVTpGz__border-bottom-left-radius: 3px;
  --mixin-1r97PxVTpGz__border-bottom-right-radius: 3px;
  --mixin-1r97PxVTpGz__border-top-left-radius: 3px;
  --mixin-1r97PxVTpGz__border-top-right-radius: 3px;
  --mixin-1r97PxVTpGz__font-family: "Inconsolata";
  --mixin-1r97PxVTpGz__padding-bottom: 3px;
  --mixin-1r97PxVTpGz__padding-left: 6px;
  --mixin-1r97PxVTpGz__padding-right: 6px;
  --mixin-1r97PxVTpGz__padding-top: 3px;
  --mixin-1r97PxVTpGz__white-space: pre-wrap;
  --mixin-2zryLUqmrdxr_white-space: pre-wrap;
  --mixin-RHKYlc8EonnN_font-family: "Inter", sans-serif;
  --mixin-RHKYlc8EonnN_color: #000000;
  --mixin-RHKYlc8EonnN_font-size: 20px;
  --mixin-RHKYlc8EonnN_font-weight: 600;
  --mixin-RHKYlc8EonnN_letter-spacing: -0.3px;
  --mixin-RHKYlc8EonnN_line-height: 1.5;
  --mixin-RHKYlc8EonnN_white-space: pre-wrap;
  --mixin-XkxoIJz4inTN_font-family: "Inter", sans-serif;
  --mixin-XkxoIJz4inTN_color: #000000;
  --mixin-XkxoIJz4inTN_font-size: 16px;
  --mixin-XkxoIJz4inTN_font-weight: 600;
  --mixin-XkxoIJz4inTN_line-height: 1.5;
  --mixin-XkxoIJz4inTN_white-space: pre-wrap;
  --mixin-0V561K2ogKx-_display: flex;
  --mixin-0V561K2ogKx-_flex-direction: column;
  --mixin-0V561K2ogKx-_align-items: stretch;
  --mixin-0V561K2ogKx-_justify-content: flex-start;
  --mixin-0V561K2ogKx-_list-style-position: outside;
  --mixin-0V561K2ogKx-_padding-left: 40px;
  --mixin-0V561K2ogKx-_position: relative;
  --mixin-0V561K2ogKx-_list-style-type: decimal;
  --mixin-0V561K2ogKx-_white-space: pre-wrap;
  --mixin-z1If-IWm6pIj_display: flex;
  --mixin-z1If-IWm6pIj_flex-direction: column;
  --mixin-z1If-IWm6pIj_align-items: stretch;
  --mixin-z1If-IWm6pIj_justify-content: flex-start;
  --mixin-z1If-IWm6pIj_list-style-position: outside;
  --mixin-z1If-IWm6pIj_padding-left: 40px;
  --mixin-z1If-IWm6pIj_position: relative;
  --mixin-z1If-IWm6pIj_list-style-type: disc;
  --mixin-z1If-IWm6pIj_white-space: pre-wrap;
}

:where(.all) {
  display: block;
  white-space: inherit;
  grid-row: auto;
  grid-column: auto;
  position: relative;
  background: none;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  box-shadow: none;
  box-sizing: border-box;
  text-decoration-line: none;
  margin: 0;
  border-width: 0px;
}
:where(.__wab_expr_html_text *) {
  white-space: inherit;
  grid-row: auto;
  grid-column: auto;
  background: none;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  box-shadow: none;
  box-sizing: border-box;
  text-decoration-line: none;
  margin: 0;
  border-width: 0px;
}

:where(.img) {
  display: inline-block;
}
:where(.__wab_expr_html_text img) {
  white-space: inherit;
}

:where(.li) {
  display: list-item;
}
:where(.__wab_expr_html_text li) {
  white-space: inherit;
}

:where(.span) {
  display: inline;
  position: static;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}
:where(.__wab_expr_html_text span) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}

:where(.input) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: linear-gradient(#ffffff, #ffffff);
  padding: 2px;
  border: 1px solid lightgray;
}
:where(.__wab_expr_html_text input) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: linear-gradient(#ffffff, #ffffff);
  padding: 2px;
  border: 1px solid lightgray;
}

:where(.textarea) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  padding: 2px;
  border: 1px solid lightgray;
}
:where(.__wab_expr_html_text textarea) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  padding: 2px;
  border: 1px solid lightgray;
}

:where(.button) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: none;
  align-items: flex-start;
  text-align: center;
  padding: 2px 6px;
  border: 1px solid lightgray;
}
:where(.__wab_expr_html_text button) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: none;
  align-items: flex-start;
  text-align: center;
  padding: 2px 6px;
  border: 1px solid lightgray;
}

:where(.code) {
  font-family: inherit;
  line-height: inherit;
}
:where(.__wab_expr_html_text code) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
}

:where(.pre) {
  font-family: inherit;
  line-height: inherit;
}
:where(.__wab_expr_html_text pre) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
}

:where(.p) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}
:where(.__wab_expr_html_text p) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}

:where(.h1) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h1) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.h2) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h2) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.h3) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h3) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.h4) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h4) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.h5) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h5) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.h6) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h6) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.address) {
  font-style: inherit;
}
:where(.__wab_expr_html_text address) {
  white-space: inherit;
  font-style: inherit;
}

:where(.a) {
  color: inherit;
}
:where(.__wab_expr_html_text a) {
  white-space: inherit;
  color: inherit;
}

:where(.ol) {
  list-style-type: none;
  padding: 0;
}
:where(.__wab_expr_html_text ol) {
  white-space: inherit;
  list-style-type: none;
  padding: 0;
}

:where(.ul) {
  list-style-type: none;
  padding: 0;
}
:where(.__wab_expr_html_text ul) {
  white-space: inherit;
  list-style-type: none;
  padding: 0;
}

:where(.select) {
  padding: 2px 6px;
}
:where(.__wab_expr_html_text select) {
  white-space: inherit;
  padding: 2px 6px;
}

.plasmic_default__component_wrapper {
  display: grid;
}
.plasmic_default__inline {
  display: inline;
}
.plasmic_page_wrapper {
  display: flex;
  width: 100%;
  min-height: 100vh;
  align-items: stretch;
  align-self: start;
}
.plasmic_page_wrapper > * {
  height: auto !important;
}
.__wab_expr_html_text {
  white-space: normal;
}
:where(.root_reset) {
  font-family: var(--mixin-AblFl5qF5ihA_font-family);
  font-size: var(--mixin-AblFl5qF5ihA_font-size);
  font-weight: var(--mixin-AblFl5qF5ihA_font-weight);
  font-style: var(--mixin-AblFl5qF5ihA_font-style);
  color: var(--mixin-AblFl5qF5ihA_color);
  text-align: var(--mixin-AblFl5qF5ihA_text-align);
  text-transform: var(--mixin-AblFl5qF5ihA_text-transform);
  line-height: var(--mixin-AblFl5qF5ihA_line-height);
  letter-spacing: var(--mixin-AblFl5qF5ihA_letter-spacing);
  white-space: var(--mixin-AblFl5qF5ihA_white-space);
}

:where(.root_reset) h1:where(.h1),
h1:where(.root_reset.h1),
:where(.root_reset .__wab_expr_html_text) h1,
:where(.root_reset_tags) h1,
h1:where(.root_reset_tags) {
  color: var(--mixin-V7HNa5m19ZAQ_color);
  font-weight: var(--mixin-V7HNa5m19ZAQ_font-weight);
  font-size: var(--mixin-V7HNa5m19ZAQ_font-size);
  line-height: var(--mixin-V7HNa5m19ZAQ_line-height);
  letter-spacing: var(--mixin-V7HNa5m19ZAQ_letter-spacing);
  font-family: var(--mixin-V7HNa5m19ZAQ_font-family);
  text-align: var(--mixin-V7HNa5m19ZAQ_text-align);
}

:where(.root_reset) h2:where(.h2),
h2:where(.root_reset.h2),
:where(.root_reset .__wab_expr_html_text) h2,
:where(.root_reset_tags) h2,
h2:where(.root_reset_tags) {
  color: var(--mixin-T3OoqWGpfU_h_color);
  font-weight: var(--mixin-T3OoqWGpfU_h_font-weight);
  font-size: var(--mixin-T3OoqWGpfU_h_font-size);
  line-height: var(--mixin-T3OoqWGpfU_h_line-height);
  letter-spacing: var(--mixin-T3OoqWGpfU_h_letter-spacing);
  font-family: var(--mixin-T3OoqWGpfU_h_font-family);
}

:where(.root_reset) a:where(.a),
a:where(.root_reset.a),
:where(.root_reset .__wab_expr_html_text) a,
:where(.root_reset_tags) a,
a:where(.root_reset_tags) {
  color: var(--mixin-yr6e-AnHZQPH_color);
  font-size: var(--mixin-yr6e-AnHZQPH_font-size);
  text-transform: var(--mixin-yr6e-AnHZQPH_text-transform);
  font-family: var(--mixin-yr6e-AnHZQPH_font-family);
  font-weight: var(--mixin-yr6e-AnHZQPH_font-weight);
  text-decoration-line: var(--mixin-yr6e-AnHZQPH_text-decoration-line);
}

:where(.root_reset) h3:where(.h3),
h3:where(.root_reset.h3),
:where(.root_reset .__wab_expr_html_text) h3,
:where(.root_reset_tags) h3,
h3:where(.root_reset_tags) {
  color: var(--mixin-Xef1mibqwsOk_color);
  font-weight: var(--mixin-Xef1mibqwsOk_font-weight);
  font-size: var(--mixin-Xef1mibqwsOk_font-size);
  line-height: var(--mixin-Xef1mibqwsOk_line-height);
  letter-spacing: var(--mixin-Xef1mibqwsOk_letter-spacing);
  font-family: var(--mixin-Xef1mibqwsOk_font-family);
}

:where(.root_reset) h4:where(.h4),
h4:where(.root_reset.h4),
:where(.root_reset .__wab_expr_html_text) h4,
:where(.root_reset_tags) h4,
h4:where(.root_reset_tags) {
  font-family: var(--mixin-I_gcNeGjfyB1_font-family);
  color: var(--mixin-I_gcNeGjfyB1_color);
  font-size: var(--mixin-I_gcNeGjfyB1_font-size);
  font-weight: var(--mixin-I_gcNeGjfyB1_font-weight);
  letter-spacing: var(--mixin-I_gcNeGjfyB1_letter-spacing);
  line-height: var(--mixin-I_gcNeGjfyB1_line-height);
}

:where(.root_reset) code:where(.code),
code:where(.root_reset.code),
:where(.root_reset .__wab_expr_html_text) code,
:where(.root_reset_tags) code,
code:where(.root_reset_tags) {
  background: #f8f8f8;
  font-family: var(--mixin-o3Ksqk6jDPX3_font-family);
  border-radius: var(--mixin-o3Ksqk6jDPX3_border-top-left-radius)
    var(--mixin-o3Ksqk6jDPX3_border-top-right-radius)
    var(--mixin-o3Ksqk6jDPX3_border-bottom-right-radius)
    var(--mixin-o3Ksqk6jDPX3_border-bottom-left-radius);
  padding: var(--mixin-o3Ksqk6jDPX3_padding-top)
    var(--mixin-o3Ksqk6jDPX3_padding-right)
    var(--mixin-o3Ksqk6jDPX3_padding-bottom)
    var(--mixin-o3Ksqk6jDPX3_padding-left);
  border-top: var(--mixin-o3Ksqk6jDPX3_border-top-width)
    var(--mixin-o3Ksqk6jDPX3_border-top-style)
    var(--mixin-o3Ksqk6jDPX3_border-top-color);
  border-right: var(--mixin-o3Ksqk6jDPX3_border-right-width)
    var(--mixin-o3Ksqk6jDPX3_border-right-style)
    var(--mixin-o3Ksqk6jDPX3_border-right-color);
  border-bottom: var(--mixin-o3Ksqk6jDPX3_border-bottom-width)
    var(--mixin-o3Ksqk6jDPX3_border-bottom-style)
    var(--mixin-o3Ksqk6jDPX3_border-bottom-color);
  border-left: var(--mixin-o3Ksqk6jDPX3_border-left-width)
    var(--mixin-o3Ksqk6jDPX3_border-left-style)
    var(--mixin-o3Ksqk6jDPX3_border-left-color);
}

:where(.root_reset) blockquote:where(.blockquote),
blockquote:where(.root_reset.blockquote),
:where(.root_reset .__wab_expr_html_text) blockquote,
:where(.root_reset_tags) blockquote,
blockquote:where(.root_reset_tags) {
  color: var(--mixin-r4DaWbUlDAKT_color);
  padding-left: var(--mixin-r4DaWbUlDAKT_padding-left);
  border-left: var(--mixin-r4DaWbUlDAKT_border-left-width)
    var(--mixin-r4DaWbUlDAKT_border-left-style)
    var(--mixin-r4DaWbUlDAKT_border-left-color);
}

:where(.root_reset) pre:where(.pre),
pre:where(.root_reset.pre),
:where(.root_reset .__wab_expr_html_text) pre,
:where(.root_reset_tags) pre,
pre:where(.root_reset_tags) {
  background: #f8f8f8;
  font-family: var(--mixin-1r97PxVTpGz__font-family);
  border-radius: var(--mixin-1r97PxVTpGz__border-top-left-radius)
    var(--mixin-1r97PxVTpGz__border-top-right-radius)
    var(--mixin-1r97PxVTpGz__border-bottom-right-radius)
    var(--mixin-1r97PxVTpGz__border-bottom-left-radius);
  padding: var(--mixin-1r97PxVTpGz__padding-top)
    var(--mixin-1r97PxVTpGz__padding-right)
    var(--mixin-1r97PxVTpGz__padding-bottom)
    var(--mixin-1r97PxVTpGz__padding-left);
  border-top: var(--mixin-1r97PxVTpGz__border-top-width)
    var(--mixin-1r97PxVTpGz__border-top-style)
    var(--mixin-1r97PxVTpGz__border-top-color);
  border-right: var(--mixin-1r97PxVTpGz__border-right-width)
    var(--mixin-1r97PxVTpGz__border-right-style)
    var(--mixin-1r97PxVTpGz__border-right-color);
  border-bottom: var(--mixin-1r97PxVTpGz__border-bottom-width)
    var(--mixin-1r97PxVTpGz__border-bottom-style)
    var(--mixin-1r97PxVTpGz__border-bottom-color);
  border-left: var(--mixin-1r97PxVTpGz__border-left-width)
    var(--mixin-1r97PxVTpGz__border-left-style)
    var(--mixin-1r97PxVTpGz__border-left-color);
}

:where(.root_reset) a:where(.a):hover,
a:where(.root_reset.a):hover,
:where(.root_reset .__wab_expr_html_text) a:hover,
:where(.root_reset_tags) a:hover,
a:where(.root_reset_tags):hover {
}

:where(.root_reset) h5:where(.h5),
h5:where(.root_reset.h5),
:where(.root_reset .__wab_expr_html_text) h5,
:where(.root_reset_tags) h5,
h5:where(.root_reset_tags) {
  font-family: var(--mixin-RHKYlc8EonnN_font-family);
  color: var(--mixin-RHKYlc8EonnN_color);
  font-size: var(--mixin-RHKYlc8EonnN_font-size);
  font-weight: var(--mixin-RHKYlc8EonnN_font-weight);
  letter-spacing: var(--mixin-RHKYlc8EonnN_letter-spacing);
  line-height: var(--mixin-RHKYlc8EonnN_line-height);
}

:where(.root_reset) h6:where(.h6),
h6:where(.root_reset.h6),
:where(.root_reset .__wab_expr_html_text) h6,
:where(.root_reset_tags) h6,
h6:where(.root_reset_tags) {
  font-family: var(--mixin-XkxoIJz4inTN_font-family);
  color: var(--mixin-XkxoIJz4inTN_color);
  font-size: var(--mixin-XkxoIJz4inTN_font-size);
  font-weight: var(--mixin-XkxoIJz4inTN_font-weight);
  line-height: var(--mixin-XkxoIJz4inTN_line-height);
}

:where(.root_reset) ol:where(.ol),
ol:where(.root_reset.ol),
:where(.root_reset .__wab_expr_html_text) ol,
:where(.root_reset_tags) ol,
ol:where(.root_reset_tags) {
  display: var(--mixin-0V561K2ogKx-_display);
  flex-direction: var(--mixin-0V561K2ogKx-_flex-direction);
  align-items: var(--mixin-0V561K2ogKx-_align-items);
  justify-content: var(--mixin-0V561K2ogKx-_justify-content);
  list-style-position: var(--mixin-0V561K2ogKx-_list-style-position);
  padding-left: var(--mixin-0V561K2ogKx-_padding-left);
  position: var(--mixin-0V561K2ogKx-_position);
  list-style-type: var(--mixin-0V561K2ogKx-_list-style-type);
  flex-column-gap: var(--mixin-0V561K2ogKx-_flex-column-gap);
}

:where(.root_reset) ul:where(.ul),
ul:where(.root_reset.ul),
:where(.root_reset .__wab_expr_html_text) ul,
:where(.root_reset_tags) ul,
ul:where(.root_reset_tags) {
  display: var(--mixin-z1If-IWm6pIj_display);
  flex-direction: var(--mixin-z1If-IWm6pIj_flex-direction);
  align-items: var(--mixin-z1If-IWm6pIj_align-items);
  justify-content: var(--mixin-z1If-IWm6pIj_justify-content);
  list-style-position: var(--mixin-z1If-IWm6pIj_list-style-position);
  padding-left: var(--mixin-z1If-IWm6pIj_padding-left);
  position: var(--mixin-z1If-IWm6pIj_position);
  list-style-type: var(--mixin-z1If-IWm6pIj_list-style-type);
  flex-column-gap: var(--mixin-z1If-IWm6pIj_flex-column-gap);
}
