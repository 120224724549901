.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  position: relative;
  margin-left: 0px;
  min-width: 0;
  padding: 8px 26px 8px 13px;
}
.link {
  position: relative;
  display: flex;
}
.img {
  object-fit: cover;
  max-width: 100%;
  width: 346px;
  height: 460px;
  flex-shrink: 0;
  margin: 5px;
}
.img > picture > img {
  object-fit: cover;
}
.img > :global(.__wab_img-spacer) > img {
  object-fit: cover;
}
.freeBox {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  flex-direction: row;
}
.slotTargetChildren {
  font-size: 3em;
  font-weight: 700;
  color: var(--token-AB3mswlcs);
}
