.root {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-width: 0;
  min-height: 0;
  padding: 0px;
}
.root > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  min-height: 0;
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-top: 0px;
}
.homepageNavbar:global(.__wab_instance) {
  position: relative;
  margin-bottom: 2px;
}
.hero {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  align-content: unset;
  background: var(--token-AB3mswlcs);
  margin-top: calc(0px + 0px) !important;
  align-self: center;
  height: 100%;
  min-width: 0;
  min-height: 0;
  padding: 42px 30px 86px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .hero {
    margin-top: calc(0px + 0px) !important;
  }
}
.columns___7SaDt {
  --plsmc-rc-col-gap: 0px;
}
.columns___7SaDt {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  max-width: 100%;
  flex-shrink: 1;
  flex-wrap: nowrap;
  overflow: visible;
  align-self: center;
  flex-grow: 1;
  min-width: 0;
  min-height: 0;
  padding: 8px;
}
.columns___7SaDt > :nth-child(2n + 1) {
  width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 6 / 12);
}
.columns___7SaDt > :nth-child(2n + 2) {
  width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 6 / 12);
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns___7SaDt {
    width: 100%;
    height: auto;
    flex-direction: row;
    flex-wrap: wrap;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns___7SaDt > :nth-child(1n + 1) {
    width: calc((100% - 1 * var(--plsmc-rc-col-gap, 0px)) * 12 / 12);
  }
}
.column__vDyml {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.text__yQqrZ {
  padding-bottom: 0px;
  text-align: left;
  font-size: 2.5em;
  max-width: 466px;
  width: 100%;
  color: var(--token-YsAcaIub0);
  font-family: "Lora", serif;
  margin-bottom: 89px;
  align-self: center;
  margin-top: 38px;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .text__yQqrZ {
    text-align: center;
  }
}
.button:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  align-self: auto;
  height: 56px;
  width: 285px;
  flex-shrink: 0;
  margin: 0px 152px 0px 0px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .button:global(.__wab_instance) {
    margin-right: 0px;
    align-self: center;
  }
}
.svg___9N7L6 {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.text__m1Iip {
  color: var(--token-YsAcaIub0);
  text-align: center;
  font-size: 1.2em;
  font-family: "Lora", serif;
}
.svg__gaX3U {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.column__uKVz {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.freeBox__aMkMx {
  display: flex;
  position: relative;
  flex-direction: column;
  margin-right: 0px;
  max-width: none;
  padding-top: 0px;
  align-self: flex-start;
  padding-right: 0px;
  width: 100%;
  padding-left: 0px;
  height: 100%;
  min-width: 0;
  min-height: 0;
}
.freeBox__aMkMx > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 0;
  min-height: 0;
  margin-top: calc(0px - 24px);
  height: calc(100% + 24px);
}
.freeBox__aMkMx > :global(.__wab_flex-container) > *,
.freeBox__aMkMx > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__aMkMx > :global(.__wab_flex-container) > picture > img,
.freeBox__aMkMx
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 24px;
}
.freeBox___2UgUf {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.img___6CuW {
  position: relative;
  object-fit: contain;
  max-width: 100%;
  width: 466px;
  object-position: right center;
  height: 415px;
  left: auto;
  top: auto;
  flex-shrink: 0;
}
.img___6CuW > picture > img {
  object-fit: contain;
  object-position: right center;
}
.img___6CuW > :global(.__wab_img-spacer) > img {
  object-fit: contain;
  object-position: right center;
}
.jahanjeZaDecuIOdraske {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  min-width: 0;
  padding: 60px 80px 42px;
}
.jahanjeZaDecuIOdraske > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  min-width: 0;
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.jahanjeZaDecuIOdraske > :global(.__wab_flex-container) > *,
.jahanjeZaDecuIOdraske
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.jahanjeZaDecuIOdraske > :global(.__wab_flex-container) > picture > img,
.jahanjeZaDecuIOdraske
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 0px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .jahanjeZaDecuIOdraske {
    width: 100%;
    min-width: 0;
    padding: 50px 32px;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .jahanjeZaDecuIOdraske > :global(.__wab_flex-container) {
    min-width: 0;
  }
}
.freeBox__oLe8Y {
  display: flex;
  flex-direction: column;
}
.freeBox__oLe8Y > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: calc(0px - 16px);
  height: calc(100% + 16px);
}
.freeBox__oLe8Y > :global(.__wab_flex-container) > *,
.freeBox__oLe8Y > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__oLe8Y > :global(.__wab_flex-container) > picture > img,
.freeBox__oLe8Y
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 16px;
}
.freeBox__uWlWh {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.h2__zBDg {
  font-size: 2.5em;
  font-family: "Lora", serif;
  font-weight: 600;
  text-align: center;
  margin: 20px 20px 10px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .h2__zBDg {
    text-align: center;
  }
}
.upoznajKonje2 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px;
}
.columns__nT6O4 {
  --plsmc-rc-col-gap: 0px;
}
.columns__nT6O4 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  flex-shrink: 1;
  flex-wrap: nowrap;
  margin-top: 96px;
  margin-bottom: 78px;
  min-width: 0;
  padding: 0px 8px 8px;
}
.columns__nT6O4 > :nth-child(3n + 1) {
  width: calc((100% - 3 * var(--plsmc-rc-col-gap, 0px)) * 4 / 12);
}
.columns__nT6O4 > :nth-child(3n + 2) {
  width: calc((100% - 3 * var(--plsmc-rc-col-gap, 0px)) * 4 / 12);
}
.columns__nT6O4 > :nth-child(3n + 3) {
  width: calc((100% - 3 * var(--plsmc-rc-col-gap, 0px)) * 4 / 12);
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__nT6O4 {
    width: 100%;
    height: auto;
    flex-direction: row;
    flex-wrap: wrap;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__nT6O4 > :nth-child(1n + 1) {
    width: calc((100% - 1 * var(--plsmc-rc-col-gap, 0px)) * 12 / 12);
  }
}
.column__bLXlN {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.img__yqIjd {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 649px;
  height: 472px;
  flex-shrink: 0;
}
.img__yqIjd > picture > img {
  object-fit: cover;
}
.img__yqIjd > :global(.__wab_img-spacer) > img {
  object-fit: cover;
}
.column__hevif {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.img__sGskr {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 649px;
  height: 472px;
  flex-shrink: 0;
}
.img__sGskr > picture > img {
  object-fit: cover;
}
.img__sGskr > :global(.__wab_img-spacer) > img {
  object-fit: cover;
}
.column__fUtp4 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.img___4Txag {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 649px;
  height: 472px;
  flex-shrink: 0;
}
.img___4Txag > picture > img {
  object-fit: cover;
}
.img___4Txag > :global(.__wab_img-spacer) > img {
  object-fit: cover;
}
.sekcija3PlavoOdJutra {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  background: var(--token-AB3mswlcs);
  margin-right: 0px;
  margin-left: 0px;
  margin-top: calc(0px + 0px) !important;
  margin-bottom: 0px;
  height: auto;
  min-width: 0;
  padding: 5rem;
}
.sekcija3PlavoOdJutra > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 0;
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.sekcija3PlavoOdJutra > :global(.__wab_flex-container) > *,
.sekcija3PlavoOdJutra
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.sekcija3PlavoOdJutra > :global(.__wab_flex-container) > picture > img,
.sekcija3PlavoOdJutra
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 0px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .sekcija3PlavoOdJutra {
    width: 100%;
    margin-top: calc(0px + 0px) !important;
    min-width: 0;
    padding: 60px 32px;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .sekcija3PlavoOdJutra > :global(.__wab_flex-container) {
    min-width: 0;
  }
}
.columns___9A7K6 {
  --plsmc-rc-col-gap: 0px;
}
.columns___9A7K6 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  flex-shrink: 1;
  flex-wrap: nowrap;
  min-width: 0;
  padding: 8px;
}
.columns___9A7K6 > :nth-child(2n + 1) {
  width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 6 / 12);
}
.columns___9A7K6 > :nth-child(2n + 2) {
  width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 6 / 12);
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns___9A7K6 {
    width: 100%;
    height: auto;
    flex-direction: row;
    flex-wrap: wrap;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns___9A7K6 > :nth-child(1n + 1) {
    width: calc((100% - 1 * var(--plsmc-rc-col-gap, 0px)) * 12 / 12);
  }
}
.column__lvGYs {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .column__lvGYs {
    padding-bottom: 0px;
  }
}
.freeBox__epVxI {
  display: flex;
  position: relative;
  flex-direction: column;
  top: auto;
  left: auto;
  width: 100%;
  height: 100%;
  align-self: auto;
  margin-bottom: 6rem;
  min-width: 0;
  min-height: 0;
}
.freeBox__epVxI > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
  align-content: stretch;
  min-width: 0;
  min-height: 0;
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.freeBox__epVxI > :global(.__wab_flex-container) > *,
.freeBox__epVxI > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__epVxI > :global(.__wab_flex-container) > picture > img,
.freeBox__epVxI
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 0px;
}
.freeBox__o26Ab {
  display: flex;
  flex-direction: column;
  width: auto;
  max-width: none;
  align-self: auto;
  margin-bottom: 0rem;
  height: auto;
  background: none;
  margin-top: calc(0px + 0px) !important;
  margin-right: 0px;
  margin-left: 0px;
  padding: 0px;
}
.freeBox__o26Ab > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-top: calc(0px - 21px);
  height: calc(100% + 21px);
}
.freeBox__o26Ab > :global(.__wab_flex-container) > *,
.freeBox__o26Ab > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__o26Ab > :global(.__wab_flex-container) > picture > img,
.freeBox__o26Ab
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 21px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__o26Ab {
    margin-top: calc(0px + 0px) !important;
  }
}
.freeBox__lxNo2 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
}
.h2__y4Izh {
  align-self: flex-start;
  width: 100%;
  text-align: left;
  max-width: 440px;
  color: var(--token-YsAcaIub0);
  font-size: 3em;
  font-weight: 500;
  font-family: "Lora", serif;
  height: 100%;
  max-height: 200px;
  position: relative;
  left: auto;
  top: auto;
  z-index: 1;
  padding-bottom: 2px;
  margin-bottom: 38px;
  min-width: 0;
  min-height: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .h2__y4Izh {
    text-align: center;
  }
}
.text__mXtE3 {
  padding-bottom: 0px;
  text-align: left;
  font-size: 2em;
  max-width: 510px;
  width: 100%;
  color: var(--token-YsAcaIub0);
  font-family: "Lora", serif;
  font-weight: 400;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .text__mXtE3 {
    text-align: center;
    margin-bottom: 29px;
  }
}
.pogledajteNaseCene:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  margin-top: calc(10px + 21px) !important;
  margin-bottom: 3px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .pogledajteNaseCene:global(.__wab_instance) {
    align-self: center;
    margin-top: calc(10px + 21px) !important;
  }
}
.svg__zCzNk {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.text__faD5G {
  color: var(--token-YsAcaIub0);
  font-size: 1.2em;
  font-family: "Lora", serif;
}
.svg__nAqGa {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.column__vhCyJ {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 91px 8px 0px;
}
.img__k3Vfx {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 684px;
  height: 404px;
  flex-shrink: 0;
}
.img__k3Vfx > picture > img {
  object-fit: cover;
}
.img__k3Vfx > :global(.__wab_img-spacer) > img {
  object-fit: cover;
}
.text__sJrHf {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
  display: none;
}
@media (min-width: 0px) and (max-width: 768px) {
  .text__sJrHf {
    display: none;
  }
}
.textunnamedVariant__sJrHfa6O6E {
  display: none;
}
.callToAction {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  background: var(--token-FfftQnWVlqF);
  min-width: 0;
  padding: 4rem 0px 4rem 42px;
}
.callToAction > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.callToAction > :global(.__wab_flex-container) > *,
.callToAction > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.callToAction > :global(.__wab_flex-container) > picture > img,
.callToAction
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 0px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .callToAction {
    width: 100%;
    min-width: 0;
    padding: 41px 32px 28px;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .callToAction > :global(.__wab_flex-container) {
    min-width: 0;
  }
}
.freeBox__neRj {
  display: flex;
  position: relative;
  flex-direction: column;
  top: auto;
  left: auto;
  max-width: 1447px;
  width: 100%;
  min-width: 0;
}
.freeBox__neRj > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 34px);
  height: calc(100% + 34px);
}
.freeBox__neRj > :global(.__wab_flex-container) > *,
.freeBox__neRj > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__neRj > :global(.__wab_flex-container) > picture > img,
.freeBox__neRj
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 34px;
}
.columns__e3XWj {
  --plsmc-rc-col-gap: 0px;
}
.columns__e3XWj {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  align-self: center;
  width: 1560px;
  height: auto;
  flex-shrink: 1;
  flex-wrap: nowrap;
  max-width: 100%;
  padding: 8px 0px 8px 8px;
}
.columns__e3XWj > :nth-child(2n + 1) {
  width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 6 / 12);
}
.columns__e3XWj > :nth-child(2n + 2) {
  width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 6 / 12);
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__e3XWj {
    width: 100%;
    height: auto;
    flex-direction: row;
    flex-wrap: wrap;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__e3XWj > :nth-child(1n + 1) {
    width: calc((100% - 1 * var(--plsmc-rc-col-gap, 0px)) * 12 / 12);
  }
}
.column__eGljD {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.h2___82U {
  color: var(--token-AB3mswlcs);
  text-align: center;
  font-size: 32px;
  width: 100%;
  transition-property: all;
  transition-duration: 3s;
  transition-timing-function: ease-in;
  transition-delay: 1s;
  flex-shrink: 0;
  flex-grow: 1;
  font-family: "Lora", serif;
  margin-right: 0px;
  align-self: flex-end;
  margin-left: 3px;
  height: 100%;
  line-height: 1.3;
  min-width: 0;
  min-height: 0;
  -webkit-transition-property: all;
  -webkit-transition-timing-function: ease-in;
  -webkit-transition-duration: 3s;
  -webkit-transition-delay: 1s;
  padding: 0px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .h2___82U {
    text-align: center;
    margin-left: 0px;
    width: 105.88%;
    margin-right: 0px;
  }
}
.column__z0CIr {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .column__z0CIr {
    padding-top: 58px;
  }
}
.columnunnamedVariant__z0CIra6O6E {
  padding-top: 34px;
}
.zakazivanjeBtn__wmmf1:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  flex-shrink: 0;
  display: none;
}
.zakazivanjeBtnunnamedVariant__wmmf1A6O6E:global(.__wab_instance) {
  flex-shrink: 0;
  display: block;
}
.zakazivanjeBtn__ywCy3:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  flex-shrink: 0;
  display: none;
}
@media (min-width: 0px) and (max-width: 768px) {
  .zakazivanjeBtn__ywCy3:global(.__wab_instance) {
    flex-shrink: 0;
    display: block;
  }
}
.zakazivanjeBtn__gp4Nh:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 222px;
  flex-shrink: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .zakazivanjeBtn__gp4Nh:global(.__wab_instance) {
    flex-shrink: 0;
    display: none;
  }
}
.upoznajKonje {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px;
}
.columns__nuDtq {
  --plsmc-rc-col-gap: 0px;
}
.columns__nuDtq {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  flex-shrink: 1;
  flex-wrap: nowrap;
  margin-top: 96px;
  margin-bottom: 78px;
  min-width: 0;
  padding: 0px 8px 8px;
}
.columns__nuDtq > :nth-child(3n + 1) {
  width: calc((100% - 3 * var(--plsmc-rc-col-gap, 0px)) * 4 / 12);
}
.columns__nuDtq > :nth-child(3n + 2) {
  width: calc((100% - 3 * var(--plsmc-rc-col-gap, 0px)) * 4 / 12);
}
.columns__nuDtq > :nth-child(3n + 3) {
  width: calc((100% - 3 * var(--plsmc-rc-col-gap, 0px)) * 4 / 12);
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__nuDtq {
    width: 100%;
    height: auto;
    flex-direction: row;
    flex-wrap: wrap;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__nuDtq > :nth-child(1n + 1) {
    width: calc((100% - 1 * var(--plsmc-rc-col-gap, 0px)) * 12 / 12);
  }
}
.column___0IZwz {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.slikaText__cSOkH:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  margin-right: 0px;
}
.text___2ViP {
  font-family: "Lora", serif;
}
.column__zVg45 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.slikaText__e487U:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.text___5LvAz {
  font-family: "Lora", serif;
}
.column___0OVbO {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.slikaText__kTi1P:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.text__g1U4O {
  font-family: "Lora", serif;
}
.homepageFooter:global(.__wab_instance) {
  position: relative;
  height: 232px;
  flex-shrink: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .homepageFooter:global(.__wab_instance) {
    position: relative;
    height: 223px;
    flex-shrink: 0;
  }
}
.homepageFooterunnamedVariant:global(.__wab_instance) {
  height: 239px;
  flex-shrink: 0;
}
