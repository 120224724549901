.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  min-height: 0;
}
.homepageNavbar:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.text {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: var(--token-AB3mswlcs);
  padding-right: 0px;
  padding-bottom: 70px;
  align-self: stretch;
  text-align: center;
  padding-top: 70px;
  font-size: 43px;
  min-width: 0;
}
.columns___7RWv7 {
  --plsmc-rc-col-gap: 0px;
}
.columns___7RWv7 {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  align-items: stretch;
  flex-shrink: 1;
  flex-direction: row;
  flex-wrap: nowrap;
  min-width: 0;
  padding: 8px;
}
.columns___7RWv7 > :nth-child(2n + 1) {
  width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 6 / 12);
}
.columns___7RWv7 > :nth-child(2n + 2) {
  width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 6 / 12);
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns___7RWv7 {
    width: 100%;
    height: auto;
    flex-direction: row;
    flex-wrap: wrap;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns___7RWv7 > :nth-child(1n + 1) {
    width: calc((100% - 1 * var(--plsmc-rc-col-gap, 0px)) * 12 / 12);
  }
}
.column__fawys {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.img___79Dsm {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 654px;
}
.img___79Dsm > picture > img {
  object-fit: cover;
}
.img___79Dsm > :global(.__wab_img-spacer) > img {
  object-fit: cover;
}
.column__ci3JK {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px 8px 8px 0px;
}
.img__xzLv0 {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 654px;
}
.img__xzLv0 > picture > img {
  object-fit: cover;
}
.img__xzLv0 > :global(.__wab_img-spacer) > img {
  object-fit: cover;
}
.columns__nuKve {
  --plsmc-rc-col-gap: 0px;
}
.columns__nuKve {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  align-items: stretch;
  flex-shrink: 1;
  flex-direction: row;
  flex-wrap: nowrap;
  min-width: 0;
  padding: 8px;
}
.columns__nuKve > :nth-child(2n + 1) {
  width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 6 / 12);
}
.columns__nuKve > :nth-child(2n + 2) {
  width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 6 / 12);
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__nuKve {
    width: 100%;
    height: auto;
    flex-direction: row;
    flex-wrap: wrap;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__nuKve > :nth-child(1n + 1) {
    width: calc((100% - 1 * var(--plsmc-rc-col-gap, 0px)) * 12 / 12);
  }
}
.column___2U1Vu {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.img__sksFz {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 654px;
}
.img__sksFz > picture > img {
  object-fit: cover;
}
.img__sksFz > :global(.__wab_img-spacer) > img {
  object-fit: cover;
}
.column__pp73X {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px 8px 8px 0px;
}
.img__dffOb {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 654px;
}
.img__dffOb > picture > img {
  object-fit: cover;
}
.img__dffOb > :global(.__wab_img-spacer) > img {
  object-fit: cover;
}
.columns___18KaG {
  --plsmc-rc-col-gap: 0px;
}
.columns___18KaG {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  align-items: stretch;
  flex-shrink: 1;
  flex-direction: row;
  flex-wrap: nowrap;
  min-width: 0;
  padding: 8px;
}
.columns___18KaG > :nth-child(2n + 1) {
  width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 6 / 12);
}
.columns___18KaG > :nth-child(2n + 2) {
  width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 6 / 12);
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns___18KaG {
    width: 100%;
    height: auto;
    flex-direction: row;
    flex-wrap: wrap;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns___18KaG > :nth-child(1n + 1) {
    width: calc((100% - 1 * var(--plsmc-rc-col-gap, 0px)) * 12 / 12);
  }
}
.column___50QM2 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.img__vN8F {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 654px;
}
.img__vN8F > picture > img {
  object-fit: cover;
}
.img__vN8F > :global(.__wab_img-spacer) > img {
  object-fit: cover;
}
.column__uVhng {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px 8px 8px 0px;
}
.img__zTvA5 {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 654px;
}
.img__zTvA5 > picture > img {
  object-fit: cover;
}
.img__zTvA5 > :global(.__wab_img-spacer) > img {
  object-fit: cover;
}
.columns__w3UYi {
  --plsmc-rc-col-gap: 0px;
}
.columns__w3UYi {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  align-items: stretch;
  flex-shrink: 1;
  flex-direction: row;
  flex-wrap: nowrap;
  min-width: 0;
  padding: 8px;
}
.columns__w3UYi > :nth-child(2n + 1) {
  width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 6 / 12);
}
.columns__w3UYi > :nth-child(2n + 2) {
  width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 6 / 12);
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__w3UYi {
    width: 100%;
    height: auto;
    flex-direction: row;
    flex-wrap: wrap;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__w3UYi > :nth-child(1n + 1) {
    width: calc((100% - 1 * var(--plsmc-rc-col-gap, 0px)) * 12 / 12);
  }
}
.column__dLKu1 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.img__zqFh7 {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 654px;
}
.img__zqFh7 > picture > img {
  object-fit: cover;
}
.img__zqFh7 > :global(.__wab_img-spacer) > img {
  object-fit: cover;
}
.column__sAcLs {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px 8px 8px 0px;
}
.img__fLed {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 654px;
}
.img__fLed > picture > img {
  object-fit: cover;
}
.img__fLed > :global(.__wab_img-spacer) > img {
  object-fit: cover;
}
.columns__pjJmr {
  --plsmc-rc-col-gap: 0px;
}
.columns__pjJmr {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  align-items: stretch;
  flex-shrink: 1;
  flex-direction: row;
  flex-wrap: nowrap;
  min-width: 0;
  padding: 8px;
}
.columns__pjJmr > :nth-child(2n + 1) {
  width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 6 / 12);
}
.columns__pjJmr > :nth-child(2n + 2) {
  width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 6 / 12);
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__pjJmr {
    width: 100%;
    height: auto;
    flex-direction: row;
    flex-wrap: wrap;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__pjJmr > :nth-child(1n + 1) {
    width: calc((100% - 1 * var(--plsmc-rc-col-gap, 0px)) * 12 / 12);
  }
}
.column__u08T0 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.img__he1MD {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 654px;
}
.img__he1MD > picture > img {
  object-fit: cover;
}
.img__he1MD > :global(.__wab_img-spacer) > img {
  object-fit: cover;
}
.column___5H3If {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px 8px 8px 0px;
}
.img__yMyHk {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 654px;
}
.img__yMyHk > picture > img {
  object-fit: cover;
}
.img__yMyHk > :global(.__wab_img-spacer) > img {
  object-fit: cover;
}
